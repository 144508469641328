<template>

  <div class="position-absolute v-header z-3">
    <div class="col-12">
      <div class="row">
        <div class="col-12 ps-4 pe-4 d-flex align-items-center justify-content-end v-theme-header">
          <button @click="$router.go(-1)" class="btn-close-asset"></button>
        </div>
      </div>
    </div>
  </div>
  <div class="position-absolute v-content d-flex align-items-center text-center z-1">
    <div class="col-12 h-100 p-0 z-1">

      <!-- <?php 
      if($r > 0)  {
        $prev = $r-1;
        $plink = "renders-view.php?r=" . $prev . "&b=$b&n=" . $names_array[$prev] . "&f=$f";
        ?>
        <a href="<?php echo($plink); ?>" class="btn-prev"><img src="img/ui-theme/prev-icon.svg" alt="Prev"></a>
      <?php } ?> -->
      <div class="row h-100 g-0">
        <div class="col-12 h-100 position-relative z-1">
          <img :src="theme.cdnUrl + '/assets/' + assetType + '/' + viewType + '/' + currentRender.ObjectName" class="h-100">
        </div>
        <!-- <div v-for="asset in assetTypes" :key="asset.id">
          <div class="col-12 h-100 position-relative z-1" v-if="asset.id == order">
            <img :src="theme.cdnUrl + '/assets/' + assetType + '/' + viewType + '/' + asset.ObjectName" class="h-100">
          </div>
        </div> -->
      </div>
      <!-- <?php
      if($r < (count($names_array)-1)) {
      $next = $r+1;
      $nlink = "renders-view.php?r=" . $next . "&b=$b&n=" . $names_array[$next] . "&f=$f";
      ?>
      <a href="<?php echo($nlink); ?>" class="btn-next"><img src="img/ui-theme/next-icon.svg" alt="Next"></a>
      <?php } ?> -->
    </div>
  </div>

  <OffCanvas />
</template>

<script>
import OffCanvas from "@/components/OffCanvas.vue"
import global from "../global"
import BunnyNetService from "@/services/BunnyNetService.js";
import { unslugify } from "unslugify";

export default {
  name: "RenderView",
  props: [
    'assetType',
    'viewType',
    'order'
  ],
  components: {
    OffCanvas
  },
  data() {
    return {
      assetTypes: null,
      currentAsset: null,
      folderStructure: encodeURIComponent(this.theme.devFolder + '/assets/' + this.assetType + '/' + this.viewType + '/'),
      currentIndex: null
    }
  },
  created() {
    BunnyNetService.getRenders(this.folderStructure, this.assetType)
    .then(response => {
      let j = 0;
      // Remap array
      const menuItems = response.data.map((item) => {
        return {
          Guid: item.Guid,
          ObjectName: unslugify(item.ObjectName),
          LinkName: item.ObjectName,
          ComponentName: this.componentName,
          Path: item.Path,
          IsDirectory: item.IsDirectory,
          StorageZoneName: item.StorageZoneName,
          id: j++
        };
      });

      // Custom order array
      this.assetTypes = this.reorder(menuItems, this.theme.rendersOrder);
    })
  },
  methods: {
    reorder: function(data_array, real_order) {
      var i, d = {}, result = [];
      for(i=0; i<data_array.length; ++i) {
        d[data_array[i].id] = data_array[i];
      }

      for(i=0; i<real_order.length; ++i) {
        result.push(d[real_order[i]]);
      }

      return result;
    }
  },
  computed: {
    currentRender() {
      let tempRender = this.assetTypes;
      
      tempRender = tempRender.filter((item) => {
        return (item.id == this.order)
      })
      
      return tempRender[0];
    }
  },
  setup () {
    const {theme} = global

    return {
      theme
    }
  }
}
</script>