import { reactive } from "vue";

const theme = reactive({
  agentLogo: "coco-republic-footer-logo.svg",
  devLogo: "charlie-parker-header-logo.svg",
  apiUrl: "https://api.metaluxe.com.au/",
  bunnyApiUrl: "https://api.bunny.net/",
  accessKey: "ec558f34-7538-4b7e-b822-179253457e6b93932c0d-eae6-47b4-b444-1ac035b8ff7f",
  streamToken: "a2d68605-2b2f-4ae2-a527-2ba7e0fdd552",
  videoLibraryId: "59935",
  collectionId: "0958dbb9-7e04-4d85-95fc-b4c59e74bbe4",
  streamCdnUrl: "https://vz-7f5c1d3d-f84.b-cdn.net",
  streamApiKey: "a30b94da-10e7-4a05-869a4cd20545-3220-4a8d",
  storageApiPassword: "061c189c-ef51-4585-9bf956f557dd-505f-4662",
  cdnUrl: "https://charlieparker.metaluxe.com.au",
  devFolder: "/charlieparker",
  videosIcon: "/img/ui-theme/videos-icon.svg",
  rendersIcon: "/img/ui-theme/renders-icon.svg",
  rangeIcon: "/img/ui-theme/range-icon.svg",
  panoramasIcon: "/img/ui-theme/panoramas-icon.svg",
  floorplansIcon: "/img/ui-theme/floorplans-icon.svg",
  dashboardMp4: "theme/dashboard/4K_dashboard.mp4",
  dashboardWebm: "theme/dashboard/4K_dashboard.webm",
  assetsMp4: "theme/dashboard/4K_assets.mp4",
  assetsWebm: "theme/dashboard/4K_assets.webm",
  
  // top level menu
  assetsMenuOrder: [
    'apartment-type-2c'
  ],

  // asset level menu
  apartmentMenuOrder: [
    'videos',
    'virtual-tour',
    'range'
  ],
  
  // introduction asset order
  apartmentAsset: {
    videos: [ 0, 1 ],
    videosName: [
      'Cinematic',
      'Walkthrough'
    ],
    range: [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13 ],
    rangeName: [ 
      'Aleka Table Lamp',
      'Amsterdam Side Table',
      'Cannes Bed',
      'Colt Task Lamp',
      'Como Marble and Brass Coffee Table',
      'Gilles Bed',
      'Isabella Side Table',
      'Markson Dining Chair',
      'Montara Round Dining Table',
      'Monteroy Rug',
      'Odessa Entertainment Console',
      'Radar Table Lamp',
      'Seville Desk Chair',
      'Vittoria Sofa'
    ],
    rangeUrl: [
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      ''
    ],
    panoramas: [ 0 ],
    panoramasName: [ 
      'Apartment Type 2c'
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/phxJHdGilOsi?mls=1'
    ]
  },
});

export default { theme };