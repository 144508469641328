<template>
  <!-- <router-link class="event-link" :to="{ name: 'EventDetails', params: { id: event.Guid } }"> -->
  <router-link class="event-link" :to="{ name: 'DashboardHome' }">
    <div class="event-card">
      <span>{{ event.Path }}</span>
      <h4>{{ event.ObjectName }}</h4>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'EventCard',
  props: {
    event: {
      type: Object,
      default: () => {
        return {}
      }
    }
  }
};
</script>

<style scoped>
.event-card {
  padding: 20px;
  width: 250px;
  cursor: pointer;
  border: 1px solid #39495c;
  margin-bottom: 18px;
}
.event-card:hover {
  transform: scale(1.01);
  -webkit-box-shadow: 2px 2px 12px 2px rgba(0,0,0,0.15); 
  box-shadow: 2px 2px 12px 2px rgba(0,0,0,0.15);
}
.event-link {
  color: #39495c;
  text-decoration: none;
}
</style>
